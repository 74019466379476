import React from "react"
import { css } from "@emotion/core"
import { rhythm } from "../utils/typography"
import { useStaticQuery, Link, graphql } from "gatsby"

const BlogRoll = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                title
                date(formatString: "MMM DD, YYYY")
                tags
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `
  )
  return (
    <div className="blogRoll">
      {/* <h4>{data.allMarkdownRemark.totalCount} Posts</h4> */}
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <div className="blogItem" key={node.id}>
          <Link
            to={node.fields.slug}
            css={css`
              text-decoration: none;
              color: inherit;
            `}
          >
            <h3 className="title"
              css={css`
                margin-bottom: ${rhythm(1 / 4)};
              `}
            >
              {node.frontmatter.title}{" "}
              
            </h3>
          </Link>
            <div className="excerpt">
              <p>
                {node.excerpt}
              </p>
            </div>
            <div className="postDate">
              {node.frontmatter.date}
            </div>
            <div className="tags">
              {node.frontmatter.tags.map((tag, i) => (
                <span className="tag">{tag}</span>
              ))}
            </div>
        </div>
      ))}
    </div>
  )
}
export default BlogRoll