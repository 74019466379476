/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "../components/layout"
import BlogRoll from "../components/blogroll"
import { Text } from 'theme-ui'
import { Helmet } from "react-helmet"
import { css } from "@emotion/core"
import { rhythm } from "../utils/typography"

export default () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ervin Ebalo</title>
        <link rel="canonical" href="https://ervinebalo.com" />
      </Helmet>
      <section sx={{ mb: [4], p: { fontSize: [1, 1, 1], mt: 1 } }}>
        <Text sx={{ fontSize: [4, 5, 6], fontWeight: `600`, color: `heading` }} className="primary-text">
          Hi, I'm Ervin Ebalo.
        </Text>
        {/* <ul className="myList"> */}
          <p>
            <span css={css` font-family: 'Patua One',sans-serif; `}>Product builder </span> — I have a passion for solving complex problems.<br/>
            <span css={css` font-family: 'Patua One',sans-serif; `}>Dot connector </span> — I enjoy relating concepts, thoughts, and patterns.<br/>
            <span css={css` font-family: 'Patua One',sans-serif; `}>Tinkerer </span> — Learn and build. Build and learn.
          </p>
        {/* </ul> */}
        
        <div class="blockquote-wrapper">
          <div class="blockquote">
            <h1>
              A problem<br/>well-stated is half-solved
            </h1>
            <h4>&mdash;Charles Kettering</h4>
          </div>
        </div>
      </section>
      <h2 className="is-title-2">Latest Posts</h2>
      <BlogRoll />
    </Layout>
  )
}